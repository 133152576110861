var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fill-height":""}},[_c('v-col',{staticClass:"pa-12 flex",attrs:{"justify":"center","align":"center"}},[_c('img',{staticClass:"rebox-logo",attrs:{"src":require("@/assets/reBox.svg"),"alt":"rebox-logo"}}),_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var invalid = ref.invalid;
return [_c('v-form',{staticClass:"login-form",attrs:{"action":""},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.login)}}},[_c('ValidationProvider',{attrs:{"rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-text-field',{attrs:{"error-messages":errors,"success":valid,"type":"email","label":_vm.$t('login.email'),"outlined":""},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":"required|min:8"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-text-field',{attrs:{"error-messages":errors,"success":valid,"type":"password","label":_vm.$t('login.password'),"outlined":""},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})]}}],null,true)}),_c('v-btn',{staticClass:"mx-auto d-block white--text",attrs:{"id":"login-button","color":"primary","type":"submit","disabled":invalid}},[_vm._v(" "+_vm._s(_vm.$t('login.login'))+" ")])],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }