









































import Vue from 'vue';
import { mapMutations } from 'vuex';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import RepositoryFactory from '@/services/RepositoryFactory';
import ILoginRepository from '@/services/Repositories/Login/types';
import router from '@/router';
import LocalStorage from '@/services/localStorage';

const factory = new RepositoryFactory();

export default Vue.extend({
  name: 'Login.vue',
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      email: '',
      password: '',
    };
  },
  computed: {
    loginRepository(): ILoginRepository {
      return factory.get('login') as ILoginRepository;
    },
  },
  methods: {
    ...mapMutations('globals', ['SET_TOKEN', 'SET_USER_EMAIL']),
    login() {
      (async () => {
        const response = await this.loginRepository.login(this.email, this.password);
        if (!response) {
          console.log('Unauthorized');
        } else {
          this.SET_USER_EMAIL(this.email);
          LocalStorage.setItem('email', this.email);
          this.SET_TOKEN(response);
          await router.push('/');
        }
      })();
    },
  },
});
