







import Vue from 'vue';
import Login from '@/components/login/Login.vue';
import DisplayVersion from '@/components/layouts/DisplayVersion.vue';

export default Vue.extend({
  components: {
    Login,
    DisplayVersion,
  },
});
